<template>
  <div class="right-menu shipping-right">
    
    <subHeader pageName="FAQs"/>

    <tutorialsAction />
    
    <div class="db_top">
      <ValidationObserver ref="faqsObserver">
        <div class="db-container">
          <div class="viewOrderBox navBox" ref="viewOrderBox">
            <!-- add FAQ block starts here -->
            <div class="menuSideBar">
              <h4>FAQ</h4>
              <form>
                <div class="pageSelect ctmLinkBox">
                  <label for="questionField">Question</label>
                  <input 
                    type="text" 
                    name="questionField" 
                    id="questionField" 
                    placeholder="Question"
                    v-model="faqQuestion" 
                    style="margin-bottom: 10px" 
                    :disabled="tourIsActive"
                  >
                  <label for="answerField">Answer</label>
                  <textarea 
                    name="answerField" 
                    id="answerField"
                    placeholder="Answer"
                    v-model="faqAnswer" 
                    :disabled="tourIsActive"
                  ></textarea>
                </div>
                <div class="selectBtn">
                  <button @click.prevent="addFaq" id="add-faq-btn" :disabled="tourIsActive">Add FAQ</button>
                </div>
              </form>
            </div>
            <!-- add FAQ block ends here -->

            <div class="box1" id="content-box">
              <!-- loader block starts here -->
              <div class="nestableLoader" v-if="isPending" >
                <ul>
                  <li>
                    <vue-skeleton-loader
                      type="rect"
                      :width="500"
                      :height="40"
                      animation="fade"
                    />
                  </li>
                  <li>
                    <vue-skeleton-loader
                      type="rect"
                      :width="500"
                      :height="40"
                      animation="fade"
                    />
                  </li>
                  <li>
                    <vue-skeleton-loader
                      type="rect"
                      :width="500"
                      :height="40"
                      animation="fade"
                    />
                  </li>
                  <li>
                    <vue-skeleton-loader
                      type="rect"
                      :width="500"
                      :height="40"
                      animation="fade"
                    />
                  </li>
                  <li>
                    <vue-skeleton-loader
                      type="rect"
                      :width="500"
                      :height="40"
                      animation="fade"
                    />
                  </li>
                </ul>
              </div>
              <!-- loader block ends here -->

              <!-- FAQs block starts here -->
              <vue-nestable
                :maxDepth="1" 
                v-model="currentNestableItems"  
                cross-list 
                group="cross"
                v-else 
              >
                <template slot-scope="{ item }">
                  <VueNestableHandle :item="item" >
                    <span>{{ item.question.length > 45 ? item.question.substr(0,45) + "..." : item.question }}</span>
                    <div class="menuActionBtns">
                      <button 
                        type="button" 
                        class="menuEditBtn" 
                        @click="item.isExpand = !item.isExpand" 
                        @touchstart.stop="item.isExpand = !item.isExpand" 
                        :disabled="tourIsActive"
                      >
                        <svg height="48" viewBox="0 0 48 48" width="48" xmlns="http://www.w3.org/2000/svg">
                          <path d="M6 34.5v7.5h7.5l22.13-22.13-7.5-7.5-22.13 22.13zm35.41-20.41c.78-.78.78-2.05 0-2.83l-4.67-4.67c-.78-.78-2.05-.78-2.83 0l-3.66 3.66 7.5 7.5 3.66-3.66z"/>
                          <path d="M0 0h48v48h-48z" fill="none"/>
                        </svg>
                      </button>
                      <button 
                        type="button" 
                        class="menuEditBtn" 
                        @click="removeListItem(item)" 
                        @touchstart.stop="removeListItem(item)" 
                        :disabled="tourIsActive"
                      >
                        <svg height="48" viewBox="0 0 48 48" width="48" xmlns="http://www.w3.org/2000/svg">
                          <path d="M12 38c0 2.21 1.79 4 4 4h16c2.21 0 4-1.79 4-4V14H12v24zM38 8h-7l-2-2H19l-2 2h-7v4h28V8z"/>
                          <path d="M0 0h48v48H0z" fill="none"/>
                        </svg>
                      </button>
                    </div>
                  </VueNestableHandle>
                  <div :class="item.isExpand ? 'menuDropBox activeDropMenu' : 'menuDropBox'">
                    <div class="menuBorderBox">
                      <div class="navOptions">
                        <ValidationProvider name="question" rules="required" :vid="`question_`+item.id" v-slot="{ errors }">
                          <label :for="`FaqQuestion-${item.id}`">Question<span>*</span></label>
                          <input 
                            type="text" 
                            :name="`FaqQuestion-${item.id}`" 
                            :id="`FaqQuestion-${item.id}`" 
                            placeholder="Question" 
                            v-model="item.question" 
                            :disabled="tourIsActive"
                          >
                          <span class="text-danger">{{ errors[0] }}</span>
                        </ValidationProvider>
                      </div>
                      <div class="navOptions">
                        <ValidationProvider name="answer" rules="required" :vid="`answer_`+item.id" v-slot="{ errors }">
                          <label :for="`FaqAnswer-${item.id}`">Answer<span>*</span></label>
                          <textarea 
                            :name="`FaqAnswer-${item.id}`" 
                            :id="`FaqAnswer-${item.id}`" 
                            placeholder="Answer" 
                            v-model="item.answer" 
                            :disabled="tourIsActive"
                          ></textarea>
                          <span class="text-danger">{{ errors[0] }}</span>
                        </ValidationProvider>
                      </div>
                      <div class="saveMenuBtn">
                        <button @click="item.isExpand = !item.isExpand" :disabled="tourIsActive">
                          Save
                        </button>
                      </div>
                    </div>
                  </div>
                  <!-- Content -->
                </template>
                <div slot="placeholder">
                  <b>No Content Available</b>
                </div>
              </vue-nestable>
              <!-- FAQs block ends here -->
            </div>
          </div>
          <!-- save FAQs block starts here -->
          <div class="saveMenuBtn">
            <loaderBtn v-if="saveIsLoading"/>
            <button 
              v-else @click.prevent="validate" 
              id="save-btn" 
              :disabled="tourIsActive || checkEqual()"
            >
              Save FAQs
            </button>
          </div>
          <!-- save FAQs block ends here -->
        </div>
      </ValidationObserver>
    </div>

    <v-tour name="FAQsTour" :steps="tourSteps" :options="tourOptions" :callbacks="tourCallbacks"></v-tour>

  </div>
</template>

<script>
import { VueNestable, VueNestableHandle } from 'vue-nestable';
import {MessageBox, Message} from 'element-ui';
import { v4 as uuidv4 } from 'uuid';
import _ from 'lodash';
import subHeader from "@/components/subHeader.vue";
import { mapGetters } from 'vuex';
import tutorialsAction from "@/components/tutorialsAction.vue";
import loaderBtn from "@/components/loaderBtn.vue";

export default {
  name: 'FAQs',
  metaInfo() {
    return {
      title: 'Content Management | FAQs | YeetCommerce',
    };
  },
  data() {
    return {
      boxisLoading: false,
      searchedCustomer: '',
      currentNestableItems: [],
      tempNestableItems: [],
      saveIsLoading: false,
      //FAQ management,
      faqQuestion: '',
      faqAnswer: '',
      //vue-tour
      tourOptions: {
        useKeyboardNavigation: false,
        labels: {
          buttonSkip: 'Skip Tour',
          buttonPrevious: 'Previous',
          buttonNext: 'Next',
          buttonStop: 'Finish Tour'
        }
      },
      tourCallbacks: {
        onPreviousStep: this.handlePreviousStep,
        onNextStep: this.handleNextStep,
        // onStart: this.handleTourStart,
        onStop: this.handleTourStop,
        onFinish: this.handleTourFinish,
        onSkip: this.handleTourSkip
      },
      tourSteps:[
        //header steps
        {
          target: '#tour-start-btn',
          content: `<strong>Start Tour</strong><br>Provides insights into the available functionalities within a specific menu, helping you understand what actions you can perform.`,
          params: {
            highlight: true,
            enableScrolling: false,
            placement: 'bottom'
          }
        },
        {
          target: '#master-search',
          content: `<strong>Master Search</strong><br>Enables you to perform searches from all over the dashboard.`,
          params: {
            highlight: true,
            enableScrolling: false
          },
        },
        {
          target: '#add-new-dropDown',
          content: `<strong>Add New Record</strong><br>Enables you to add new orders, products and categories on the go.`,
          params: {
            highlight: true,
            enableScrolling: false
          }
        },
        {
          target: '#help-link',
          content: `<strong>Help</strong><br>If you require any assistance, feel free to reach out to us.`,
          params: {
            highlight: true,
            enableScrolling: false
          }
        },
        {
          target: '#user-options-dropdown',
          content: `<strong>User Information</strong><br>Presents essential details about you along with a selection of available options.`,
          params: {
            highlight: true,
            enableScrolling: false
          }
        },
        //FAQs steps
        {
          target: '#questionField',
          content: `<strong>Question</strong><br>You have the ability to input a question using this field.`,
          params: {
            highlight: true,
            enableScrolling: false,
            placement:"top"
          }
        },
        {
          target: '#answerField',
          content: `<strong>Answer</strong><br>Using this field, you can provide the answer corresponding to the added question.`,
          params: {
            highlight: true,
            enableScrolling: false,
            placement:"bottom"
          }
        },
        {
          target: '#add-faq-btn',
          content: `<strong>ADD FAQ</strong><br>By clicking this button, you can add the entered question along with its corresponding answer.`,
          params: {
            highlight: true,
            enableScrolling: false,
            placement:"right"
          }
        },
        {
          target: '#content-box',
          content: `<strong>Content Box</strong><br>You can edit the added FAQs by clicking the pencil icon located at the top of each tile. 
          Additionally, the bin icon next to the pencil icon allows you to delete them.`,
          params: {
            highlight: true,
            enableScrolling: false,
            placement:"left"
          }
        },
        {
          target: '#save-btn',
          content: `<strong>Save FAQs</strong><br>Clicking this button will save the changes after you have finished adding new FAQs or editing existing ones.`,
          params: {
            highlight: true,
            enableScrolling: false,
            placement:"right"
          }
        },
      ],
      tourIsActive:false
    }
  },
  components:{
    VueNestable,
    VueNestableHandle,
    subHeader,
    tutorialsAction,
    loaderBtn
  },
  computed:{
    ...mapGetters({
      settings:'settings_module/settings',
      storeFaqs:'faqs_module/faqs',
      isPending:'faqs_module/isPending'
    })
  },
  watch:{
    storeFaqs:{
      handler:function(value){

        this.currentNestableItems = _.cloneDeep(value);
        this.tempNestableItems = _.cloneDeep(value);

      },deep:true
    }
  },
  methods:{
    //used in faqs
    addFaq(){

      if(this.faqQuestion.length == 0){

        this.$notify({
          type: 'info',
          title: 'Question Missing',
          message: "Please ensure the question field is filled.",
        });

        return

      }

      if(this.faqAnswer.length == 0){

        this.$notify({
          type: 'info',
          title: 'Answer Missing',
          message: "Please ensure the answer field is filled.",
        });

        return

      }

      let uuid = uuidv4();
      let page = [
        {
          children:[],
          id: uuidv4(),
          question: this.faqQuestion,
          answer:this.faqAnswer,
          uid:uuid,
          isExpand:false,
          isOpenNewTab:false
        }
      ];

      this.currentNestableItems = [...this.currentNestableItems, ...page];

      this.faqQuestion = "";
      this.faqAnswer = "";

      this.$notify({
        type: 'success',
        title: 'Success',
        message: "FAQ has been successfully added. Save to make it visible to your customers.",
      });

    },
    validate(){

      this.$refs.faqsObserver.validate().then(success => {

        if(!success){
        
          const errors = Object.entries(this.$refs.faqsObserver.errors)
          .map(([key, value]) => ({ key, value })).filter(error => error["value"].length);

          this.$refs.faqsObserver.refs[errors[0]["key"]].$el.scrollIntoView({ behavior: "smooth", block: "center" });

          let allErrors = Object.entries(this.$refs.faqsObserver.errors).map(([key,value]) => ({ key,value }));

          allErrors.forEach((error)=>{

            let value = null;

            if(error.value.length > 0){
              
              value = error.key.split("_")

              this.currentNestableItems.forEach(item => {

                if(item.id == value[1]){

                  item.isExpand = true

                  return 

                }

              });

            }

          });

          this.$notify({
            type: 'error',
            title: 'Validation Failed',
            message: "Check the content to eliminate validation errors.",
          });

          return

        }else{

          this.saveFaqs();

        }

      });

    },
    async saveFaqs(){

      let formData = new FormData();

      formData.append('content',JSON.stringify(this.currentNestableItems));

      this.saveIsLoading = true;
      try{
        let res = await this.$axios.post('/faq/update',formData);
        if(res.data.status_code == '1182'){

          this.$notify({
            type: 'success',
            title: 'Success',
            message: res.data.message,
          });
          
          this.$store.commit('faqs_module/update_faqs',this.currentNestableItems);

        }
      }catch(error){

        this.$message({
          type: 'error',
          showClose: true,
          message: error.response ? error.response.data.message : error.message,
        });

      }finally{

        this.saveIsLoading = false;

      }
      
    },
    removeListItem(item){

      MessageBox.confirm(
        'Are you sure you want to delete?',
        'Warning',
        {
          type: 'warning',
          confirmButtonText: 'Yes',
          cancelButtonText: 'Cancel',
        }
      ).then(async ()=>{
        
        let uuid = item.uid;

        function myfilter(value, uuid){

          let temp = [];

          value.forEach(el=>{

            if(el.children && el.children.length > 0){

              el.children = myfilter(el.children, uuid);

            }

            if(el.uid != uuid){

              temp.push(el);

            }

          });
          
          return temp;
        }

        let temp = _.cloneDeep(this.currentNestableItems);

        this.currentNestableItems = myfilter(temp, uuid);

      }).catch(()=>{});
      
    },
    checkEqual(){

      const areEqual = _.isEqual(this.tempNestableItems, this.currentNestableItems);

      return areEqual

    },
    //tour methods
    startFAQsTour(){

      this.$tours.FAQsTour.start();

      this.tourIsActive = true;

    },
    handleNextStep(currentStep){
    },
    handlePreviousStep(currentStep){
    },
    handleTourStop(){

      this.tourIsActive = false;

    },
    handleTourFinish(){

      this.tourIsActive = false;

    },
    handleTourSkip(){

      this.tourIsActive = false;

    },
  },
  async beforeMount(){

    try{

      if(!this.storeFaqs || this.storeFaqs.length == 0){

        this.$store.dispatch("faqs_module/fetchFAQsRequest");
        
      }else{

        this.tempNestableItems = _.cloneDeep(this.storeFaqs);
        this.currentNestableItems = _.cloneDeep(this.storeFaqs);

      }

    }catch(error){

      this.$message({
        type: 'error',
        showClose: true,
        message: error.response ? error.response.data.message : error.message,
      });

    }

  },
  beforeRouteLeave(to, from, next) {

    if(this.faqQuestion.length > 0 || this.faqAnswer.length > 0 || this.tempNestableItems.length != this.currentNestableItems.length){

      MessageBox.confirm(
        'You have unsaved changes, Proceeding will discard them',
        'Confirm',
        {
          distinguishCancelAndClose: true,
          confirmButtonText: 'Process',
          cancelButtonText: 'Stay',
        }
      ).then(()=>{

        next();

      }).catch(()=>{})

    }else{

      next();

    }

  },
  mounted(){
  },
  beforeDestroy(){
  },
};
</script>
<style>
.active-filter{
  border: 1px solid #1fc2c2;
  border-radius: 50px;
  padding: 0px 10px;
  text-align: center;
  color: #1fc2c2;
}
.nestable {
  position: relative;
}
.nestable-rtl {
  direction: rtl;
}
.nestable .nestable-list {
  margin: 0;
  padding: 0 0 0 40px;
  list-style-type: none;
}
.nestable-rtl .nestable-list {
  padding: 0 40px 0 0;
}
.nestable > .nestable-list {
  padding: 0;
}
.nestable-item,
.nestable-item-copy {
  margin: 10px 0 0;
}
.nestable-item:first-child,
.nestable-item-copy:first-child {
  margin-top: 0;
}
.nestable-item .nestable-list,
.nestable-item-copy .nestable-list {
  margin-top: 10px;
}
.nestable-item {
  position: relative;
}
.nestable-item.is-dragging .nestable-list {
  pointer-events: none;
}
.nestable-item.is-dragging * {
  opacity: 0;
  filter: alpha(opacity=0);
}
.nestable-item.is-dragging:before {
  content: ' ';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(106, 127, 233, 0.274);
  border: 1px dashed rgb(73, 100, 241);
  -webkit-border-radius: 5px;
  border-radius: 5px;
}
.nestable-drag-layer {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  pointer-events: none;
}
.nestable-rtl .nestable-drag-layer {
  left: auto;
  right: 0;
}
.nestable-drag-layer > .nestable-list {
  position: absolute;
  top: 0;
  left: 0;
  padding: 0;
  background-color: rgba(106, 127, 233, 0.274);
}
.nestable-rtl .nestable-drag-layer > .nestable-list {
  padding: 0;
}
.nestable [draggable="true"] {
  cursor: move;
}
.nestable-handle {
  display: inline;
}
.nestable-list-empty{
  display: flex;
  justify-content: center;
}
/* .swal2-container{
  z-index: 9999;
} */
</style>